
    import { defineComponent } from 'vue';  
    import { mapGetters } from 'vuex';
    import store from '@/store'
    import WatFocusCalendar from '@/components/StudyCreation/WatFocusCalendar.vue';
    import WatFocusResults from '@/components/StudyCreation/WatFocusResults.vue';
    import Separation from '@/components/StudyCreation/Separation.vue'
    import ModalBasic from '~/components/ModalBasic'
    import InfoMessage from '@/components/StudyCreation/InfoMessage.vue';
    import Button from '@/components/Button.vue';
    import { FocusSubProductTypes, userOrigin } from '@/utils/constants'
    import moment from 'moment-timezone';
    import Swal from 'sweetalert2'
import { useRoute, useRouter } from 'vue-router';

    export default defineComponent({
        name : 'WatFocusConfig',
        components:{
            WatFocusCalendar,
            WatFocusResults,
            Separation,
            ModalBasic,
            InfoMessage,
            Button
        },
        setup() {
    const router = useRouter()
    const route = useRoute()

    return {
        router,
        route
    }
},
        data: () => { 
            return{
                ranges:[],
                slots:[],
                sessionMinutes:0,
                sessionNum:0,
                studyName:'',
                country:'',
                lang: '',
                studyType:'',
                usersOrigin:'',
                testerNum:0,
                surveyHash:"",
                individualCriteria:[],
                isLoaded: false,
                activeTab:0,
                showInfoModal: false,
                watFocusId:0,
                infoSlotData:{
                    slotId:0,
                    date:'',
                    observerUrl:'',
                    moderatorUrl:'',
                    slotHash:'',
                    isComplete: '',
                    testers:[{
                        status:'',
                        testerSlotId:0,
                        nickname:'',
                        province:'',
                        gender:'',
                        bornDate:''

                    }]
                },
                videos:[],
            }
        },
        computed:{
            ...mapGetters([
                'countriesAvailable',
                'languages',
                'provinces'
            ]),
            showUrl():any{
                return this.usersOrigin == userOrigin.CLIENT
            },
            countryValue():any{
                let vm = this
                const countries: any = this.countriesAvailable || []
                const country = countries.filter((country: any) => country != undefined && country.secondaryValue.toLowerCase() == vm.country.toLowerCase())[0]
                if(country == undefined) return ""
                return this.$t(country.value)
            },
            language():any{
                let vm = this
                const languages: any = this.languages
                const language = languages.filter((lang: any) => lang != undefined && lang.secondaryValue.toLowerCase() == vm.lang.toLowerCase())[0]
                if(language == undefined) return ""
                return this.$t(language.value)
            },
            urlAccess():any{
                return process.env.VUE_APP_EXECUTION_URL + "/test/" + this.surveyHash + "/watfocus"
            },
            disabledConfirmButton() {
                if(this.infoSlotData.testers == null || this.infoSlotData.testers == undefined || this.infoSlotData.testers.length == 0) return true
                const hasConfirmedTester = this.infoSlotData.testers.some(tester => tester.status === "CONFIRMED");
                return !hasConfirmedTester;
            },
        },
        methods:{
            async fetchFocusConfig(){
                await store.dispatch('fetchWatFocusConfiguration',{contractId:this.route.params.contractId}).then((data) => {
                    this.ranges = data.ranges
                    this.sessionMinutes = data.sessionMinutes
                    this.sessionNum = data.sessionNum
                    this.studyName = data.studyName
                    this.lang = data.language
                    this.country = data.country
                    this.studyType = data.type
                    this.usersOrigin = data.usersOrigin
                    this.testerNum = data.testerNum
                    this.individualCriteria = data.individualCriteria != null && data.individualCriteria != undefined ? data.individualCriteria : []
                    this.surveyHash = data.hash
                    this.watFocusId = data.identifier
                })
            },
            async fetchWatFocusUsersSlot(){
                await store.dispatch('fetchWatFocusUserSlots',{contractId:this.route.params.contractId}).then((data) => {
                    let addedTesterIds: any = []
                    for(let slot of data) {
                        const s: any = slot
                        if(s.testers){
                            for(let tester of s.testers){
                                tester.hasConfirmedOtherSlot = this.checkConfirmedSlot(data, tester.id, slot.slotHash)
                                tester.province = this.provinces.filter( (p: any) => p.id == tester.province.id)[0].value
                                tester.bornDate = this.getDateRange(tester.bornDate, new Date())
                            }
                        }
                    }
                    this.slots = data
                    this.isLoaded = true
                })
            },
            checkConfirmedSlot(slots: any, testerId: any, slotHash: any){
                let isConfirmed = false
                for(let slot of slots){
                    if(slot.testers && slot.slotHash != slotHash){
                        for(let t of slot.testers){
                            console.log(slotHash)
                            if(t.id == testerId && (t.status == "COMPLETED" || t.status == "CONFIRMED")) isConfirmed = true
                        }
                    }
                }
                return isConfirmed
            },
            getDateRange(firstDate: any, lastDate: any){
                return moment(lastDate).diff(moment(firstDate), 'years');
            },
            focusStudyType(){
                return FocusSubProductTypes.GROUP == this.studyType ? this.$t('suite_watfocus_type_group') : this.$t('suite_watfocus_type_interview')
            },
            focusUserOrigin(){
                return userOrigin.WAT == this.usersOrigin ? 'WAT Community' : this.$t('suite_watlab_step3_origin_own2')
            },
            testerGender(gender: any){
                if(gender == 'W') return this.$t('suite_woman')
                return this.$t('suite_man')
            },
            openInfoModal(event: any){
                this.showInfoModal = true
                const urlMod = process.env.VUE_APP_SOURCE_URL + "/focus/" +this.watFocusId + "/" + event.slotHash
                this.infoSlotData = {
                    slotId: event.slotId,
                    date: moment(event.dateFrom).format('dddd DD.MM | HH:mm') + ' - ' + moment(event.dateTo).format("HH:mm"),
                    observerUrl: urlMod + "/observer",
                    moderatorUrl: urlMod,
                    testers:event.testers,
                    slotHash: event.slotHash,
                    isComplete: event.isComplete
                }
            },
            closeInfoModal(){
                this.showInfoModal = false
            },
            copy(text: string){
                const vm = this
                navigator.clipboard.writeText(text).then(() => {
                    const Toast = Swal.mixin({
                        toast: true,
                        position: "top-end",
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.onmouseenter = Swal.stopTimer;
                            toast.onmouseleave = Swal.resumeTimer;
                        }
                    });
                    Toast.fire({
                        icon: "success",
                        title: vm.$t('suite_copied_text')
                    });
                })
            },
            goToSession(slot: any){
                const routeData = this.$router.resolve({name: 'watfocusexecution', params:{
                    id: this.watFocusId,
                    slotHash: slot.slotHash
                }});
                window.open(routeData.href, '_blank');
            },
            async beforeConfirmUser(user: any){
                const vm = this
                const result = await Swal.fire({
                    text: `Vas a confirmar el usuario ${user.nickname}`,
                    icon: "warning",
                    showDenyButton: true,
                    denyButtonText: 'No',
                    confirmButtonText: 'Si'
                });

                if (result.isConfirmed) {
                    try {
                        vm.confirmUser(user)
                    } catch (error) {
                        Swal.fire("Ha ocurrido un error", "", "error");
                    }
                }
            },
            async confirmUser(user: any){
                await store.dispatch('confirmTesterWatFocus',{contractId:this.route.params.contractId, slotTesterId:user.testerSlotId}).then((data) => {
                    Swal.fire("Usuario confirmado", "", "success");
                    user.status = "CONFIRMED"
                })
            },
            async beforeCompleteSlot(slot: any){
                const vm = this
                const result = await Swal.fire({
                    title:'Vas a confirmar la sesión',
                    text: `Al confirmar la sesión cada usuario participante que haya sido confirmado será notificado con la información necesaria para participar.`,
                    icon: "warning",
                    showDenyButton: true,
                    denyButtonText: 'Cancelar',
                    confirmButtonText: 'Continuar'
                });

                if (result.isConfirmed) {
                    try {
                        vm.completeSlot(slot)
                    } catch (error) {
                        Swal.fire("Ha ocurrido un error", "", "error");
                    }
                }
            },
            async completeSlot(slot: any){
                await store.dispatch('completeWatFocusSlot',{contractId:this.route.params.contractId, slotId:slot.slotId}).then((data) => {
                    Swal.fire("Sesión confirmada", "", "success");
                })
            }
        },
        async mounted(){
            await this.fetchFocusConfig()
            await this.fetchWatFocusUsersSlot()
        }
    })
  